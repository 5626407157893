
import {defineComponent, inject, nextTick, onMounted, PropType, ref} from "vue";

// import wechatLogin from "@/api/wechatLogin";
import {useRoute} from "vue-router";
import $ from "jquery";
import { useRouter } from "vue-router";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import Clipboard from 'clipboard'

    export default defineComponent({
        name: "introduction",

        setup() {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const route = useRoute();
            const myInvitationImg = ref()
            const userInfo = inject("userInfo") as any;
            const showTabOne = ref(true);
            const urlData = ref()
            const isLogin = ref();
            const userId = ref({});
            const txtShow = ref(1);
            const router = useRouter();
            const visible = ref(false);
            const token = localStorage.getItem('token') || '';
            if(token){
                isLogin.value= true;
                myInvitationImg.value="/M/Server/createInvitationImg?token="+token;
            }

          onMounted(() => {
              $("#start").on('click', function () {
                const token = localStorage.getItem("token");
                if (!token) {
                  Modal({
                    title: "温馨提示",
                    content: "登录后才可以进行邀请",
                    onConfirm: () => {
                      router.push({
                        name: "Login",
                        query: {
                          redirect: router.currentRoute.value.fullPath,
                        },
                      });
                    },
                    onClose: () => {
                      console.log('1')
                    },
                  });
                  isLogin.value = false;
                  return false;
                }else{
                  isLogin.value = true;
                }
              });
          })
          // 二选一tab栏切换
          function tabOne() {
            showTabOne.value = true;

          }
          // 二选一tab栏切换

          // 二选一tab栏切换
          function  clickLiuCheng(txtFlag: number) {
            txtShow.value =txtFlag;
          }

          function confirm() {
            const clipboard = new Clipboard('.confirm-copy');
            clipboard.on('success', (e) => {
              Toast({
                type: "success",
                title: "复制链接成功，邀请好友注册",
              });
              // 释放内存
              visible.value = false
              clipboard.destroy()
            })

            clipboard.on('error', (e) => {
              Toast({
                type: "error",
                title: "链接拷贝失败",
              });
              // 释放内存
              clipboard.destroy()
            })
          }

          function close() {
            visible.value = false
          }


          function handleCopy() {
            const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后即可邀请，立刻前往",
                confirmText: '登录',
                onConfirm: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            } else {
              visible.value = true
              urlData.value ="http://m.bzwz.com/Invitation?invitationCode="+userInfo.id;
            }
          }

          if(localStorage.getItem('WX')){
            setTimeout(function () {
              location.reload();
            },500)
          }
          localStorage.removeItem('WX')

          return {
              myInvitationImg,
              userInfo,
              isLogin,
              tabOne,
              clickLiuCheng,
              txtShow,
              showTabOne,
              urlData,
              confirm,
              visible,
              close,
              handleCopy,

          };
        },

    });
